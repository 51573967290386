import { createSlice } from "@reduxjs/toolkit";

const menuSlice = createSlice({
    name:"menu",
    initialState:{
        isMenu:false
    },
    reducers:{
        addBoolean : (state,action) =>{
            state.isMenu = !state.isMenu;
        },
    }
});

export const {addBoolean} = menuSlice.actions;
export default menuSlice.reducer;