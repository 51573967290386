import { motion } from "framer-motion";
import { FiLink } from "react-icons/fi";
import bgimg1 from './../Images/ISROLOGO1d.png';
import bgimg2 from './../Images/IDSLOGO1d.png';
import bgimg3 from './../Images/amberLogo.png';
import styles from "./../bubble.module.css";

const SquishyCard = () => {
  return (
    <div className="bg-[#121212] lg:mt-8 pt-2 lg:mx-32 rounded-lg bg-opacity-50 h-[700px] lg:h-[650px]">
        <h2 className="flex items-center justify-center text-white font-semibold text-sm md:text-4xl lg:text-5xl lg:mt-2 ml-2 mb-6 py-2">
        <BubbleText/>
        </h2> 
        <div className="lg:mx-auto mb-10 flex flex-col mx-10 lg:flex lg:flex-row lg:items-center lg:justify-center gap-4 pt-[4px] h-[240px] w-[300px] lg:h-[240px] lg:w-[500px]">
        
        
        <Card3 heading={"Amber"} imgLogo={"null"} Role={"Frontend Engineer"}
        linkToPost={"https://www.linkedin.com/in/notyashgrover/details/experience/"}
        content={"Working as a Frontend Engineer and Developing/Updating amberstudent.com"}
        ReportLink={'https://amberstudent.com/'}
        ReportReference={"View Product"}
        />
        <Card heading={"Space Applications Centre, ISRO"} Role={"Software Development Internship"}  imgLogo={bgimg1}
        linkToPost={"https://www.linkedin.com/posts/notyashgrover_as-2023-draws-to-a-close-im-thrilled-to-activity-7147628470125174784-WFLy?utm_source=share&utm_medium=member_desktop"} 
        content={"Developed Three Fundamental Modules for the Quality Management System @SAC, ISRO. You can view the work in detail here: " } 
        ReportLink={'https://drive.google.com/file/d/1mJTs3-RI8gJS2rjAItoLj1AswziNtRzS/view?usp=sharing'}
        ReportReference={"View Internship Report"}
        />
      </div>
      <div className="mt-60 lg:mt-0 lg:mx-auto mb-10 flex flex-col mx-10 lg:flex lg:flex-row lg:items-center lg:justify-center gap-4 pt-[4px] h-[240px] w-[300px] lg:h-[240px] lg:w-[500px]">
        <Card2 heading={"Information Data Systems, IDS"} imgLogo={"null"} Role={"UX Designer Internship"}
        linkToPost={"https://www.linkedin.com/in/notyashgrover/details/experience/"}
        content={"Worked on the BBN Dashboard (Bharat Blockchain Network) and Polyversity (A Project launched by the Minister of Education). I am excited to continue exploring and learning in this dynamic field."}
        />
      </div>
    </div>
  );
};

const Card = ({content,heading,Role,linkToPost,ReportLink,ReportReference,imgLogo}) => {
  return (
    <motion.div
      whileHover="hover"
      transition={{
        duration: 1,
        ease: "backInOut",
      }}
      variants={{
        hover: {
          scale: 1.05,
        },
      }}
      className="relative h-[210px] w-[300px] lg:h-[250px] lg:w-[520px] shrink-0 overflow-hidden rounded-md mt-2 bg-[#121212] p-8"
    >
      <div className="relative z-10 text-white flex justify-between">
        <div>
        <span className="mb-1 lg:mb-3 block w-fit rounded-full bg-white/30 px-3 py-0.5 text-sm font-light text-white">
          {Role}{/* July'23 - Sept.'23 */}
        </span>
        <motion.span
          initial={{ scale: 0.85 }}
          variants={{
            hover: {
              scale: 1,
            },
          }}
          transition={{
            duration: 1,
            ease: "backInOut",
          }}
          className="my-2 block origin-top-left font-mono text-xl font-black leading-[1.2] max-w-3"
        >
          {heading}
        </motion.span>
        </div>
        <p className="hidden lg:block w-[140px] lg:w-[200px] text-sm font-thin">
          {content} <a href={ReportLink} className="underline" target="__blank">{ReportReference}</a>
        </p>
      </div>
      <a href={linkToPost} className="text-decoration-skip" target="__blank" >
      <button className="absolute bottom-2 flex items-center justify-center left-4 right-4 z-20 rounded border-2 border-white bg-white py-2 text-center font-mono font-black uppercase text-neutral-800 backdrop-blur transition-colors hover:bg-white/30 hover:text-white">
      <FiLink/> &nbsp; Know More 
      </button>
      </a>  
      <Background imgLogoo={imgLogo}/>
    </motion.div>
  );
};

const Card3 = ({content,heading,Role,linkToPost,ReportLink,ReportReference,imgLogo}) => {
  return (
    <motion.div
      whileHover="hover"
      transition={{
        duration: 1,
        ease: "backInOut",
      }}
      variants={{
        hover: {
          scale: 1.05,
        },
      }}
      className="relative h-[210px] w-[300px] lg:h-[250px] lg:w-[520px] shrink-0 overflow-hidden rounded-md mt-2 bg-[#121212] p-8"
    >
      <div className="relative z-10 text-white flex justify-between">
        <div>
        <span className="mb-1 lg:mb-3 block w-fit rounded-full bg-white/30 px-3 py-0.5 text-sm font-light text-white">
          {Role}{/* July'23 - Sept.'23 */}
        </span>
        <motion.span
          initial={{ scale: 0.85 }}
          variants={{
            hover: {
              scale: 1,
            },
          }}
          transition={{
            duration: 1,
            ease: "backInOut",
          }}
          className="my-2 block origin-top-left font-mono text-xl font-black leading-[1.2] max-w-3"
        >
          {heading}
        </motion.span>
        </div>
        <p className="hidden lg:block w-[140px] lg:w-[200px] text-sm font-thin">
          {content} <a href={ReportLink} className="underline" target="__blank">{ReportReference}</a>
        </p>
      </div>
      <a href={linkToPost} className="text-decoration-skip" target="__blank" >
      <button className="absolute bottom-2 flex items-center justify-center left-4 right-4 z-20 rounded border-2 border-white bg-white py-2 text-center font-mono font-black uppercase text-neutral-800 backdrop-blur transition-colors hover:bg-white/30 hover:text-white">
      <FiLink/> &nbsp; Know More 
      </button>
      </a>  
      <Background3/>
    </motion.div>
  );
};

const Card2 = ({content,heading,Role,linkToPost,ReportLink,ReportReference}) => {
  return (
    <motion.div
      whileHover="hover"
      transition={{
        duration: 1,
        ease: "backInOut",
      }}
      variants={{
        hover: {
          scale: 1.05,
        },
      }}
      className="relative h-[210px] w-[300px] lg:h-[250px] lg:w-[520px] shrink-0 overflow-hidden rounded-md mt-2 bg-[#121212] p-8"
    >
      <div className="relative z-10 text-white flex justify-between">
        <div>
        <span className="mb-3 block w-fit rounded-full bg-white/30 px-3 py-0.5 text-sm font-light text-white">
          {Role}{/* July'23 - Sept.'23 */}
        </span>
        <motion.span
          initial={{ scale: 0.85 }}
          variants={{
            hover: {
              scale: 1,
            },
          }}
          transition={{
            duration: 1,
            ease: "backInOut",
          }}
          className="my-2 block origin-top-left font-mono text-xl font-black leading-[1.2] max-w-3"
        >
          {heading}
        </motion.span>
        </div>
        <p className="hidden lg:block w-[140px] lg:w-[200px] text-sm font-thin">
          {content} <a href={ReportLink} className="underline" target="__blank">{ReportReference}</a>
        </p>
      </div>
      <a href={linkToPost} className="text-decoration-skip" target="__blank" >
      <button className="absolute bottom-2 flex items-center justify-center left-4 right-4 z-20 rounded border-2 border-white bg-white py-2 text-center font-mono font-black uppercase text-neutral-800 backdrop-blur transition-colors hover:bg-white/30 hover:text-white">
      <FiLink/> &nbsp; Know More 
      </button>
      </a>  
      <Background2/>
    </motion.div>
  );
};


const Background = ({imgLogoo}) => {
  return (
    <motion.svg
      width="320"
      height="384"
      viewBox="0 0 320 384"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="absolute inset-0 z-0"
      variants={{
        hover: {
          scale: 1.5,
        },
      }}
      transition={{
        duration: 1,
        ease: "backInOut",
      }}
    >
      <defs>
        <pattern
          id="backgroundPattern"
          patternUnits="userSpaceOnUse"
          width="100%"
          height="100%"
        >
          <image href={imgLogoo} className="object-scale-down" width="320" height="384" />
        </pattern>
      </defs>
      
      <motion.circle 
        variants={{
          hover: {
            scaleY: 0.5,
            y: -25,
          },
        }}
        transition={{
          duration: 1,
          ease: "backInOut",
          delay: 0.2,
        }}
        cx="160.5"
        cy="114.5"
        r="101.5"
        fill="url(#backgroundPattern)"
      />
      <motion.ellipse
        variants={{
          hover: {
            scaleY: 2.25,
            y: -25,
          },
        }}
        transition={{
          duration: 1,
          ease: "backInOut",
          delay: 0.2,
        }}
        cx="160.5"
        cy="265.5"
        rx="101.5"
        ry="43.5"
        fill="#1d1d1d"
      />
    </motion.svg>
  );
};
const Background2 = () => {
  return (
    <motion.svg
      width="320"
      height="384"
      viewBox="0 0 320 384"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="absolute inset-0 z-0"
      variants={{
        hover: {
          scale: 1.5,
        },
      }}
      transition={{
        duration: 1,
        ease: "backInOut",
      }}
    >
      <defs>
        <pattern
          id="backgroundPattern2"
          patternUnits="userSpaceOnUse"
          width="100%"
          height="100%"
        >
          <image href={bgimg2} className="object-scale-down" width="320" height="384" />
        </pattern>
      </defs>
      
      <motion.circle 
        variants={{
          hover: {
            scaleY: 0.5,
            y: -25,
          },
        }}
        transition={{
          duration: 1,
          ease: "backInOut",
          delay: 0.2,
        }}
        cx="160.5"
        cy="114.5"
        r="101.5"
        fill="url(#backgroundPattern2)"
      />
      <motion.ellipse
        variants={{
          hover: {
            scaleY: 2.25,
            y: -25,
          },
        }}
        transition={{
          duration: 1,
          ease: "backInOut",
          delay: 0.2,
        }}
        cx="160.5"
        cy="265.5"
        rx="101.5"
        ry="43.5"
        fill="#1d1d1d"
      />
    </motion.svg>
  );
};
const Background3 = () => {
  return (
    <motion.svg
      width="320"
      height="384"
      viewBox="0 0 320 384"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="absolute inset-0 z-0"
      variants={{
        hover: {
          scale: 1.5,
        },
      }}
      transition={{
        duration: 1,
        ease: "backInOut",
      }}
    >
      <defs>
        <pattern
          id="backgroundPattern3"
          patternUnits="userSpaceOnUse"
          width="100%"
          height="100%"
        >
          <image href={bgimg3} className="object-scale-down" width="320" height="384" />
        </pattern>
      </defs>
      
      <motion.circle 
        variants={{
          hover: {
            scaleY: 0.5,
            y: -25,
          },
        }}
        transition={{
          duration: 1,
          ease: "backInOut",
          delay: 0.2,
        }}
        cx="160.5"
        cy="114.5"
        r="101.5"
        fill="url(#backgroundPattern3)"
      />
      <motion.ellipse
        variants={{
          hover: {
            scaleY: 2.25,
            y: -25,
          },
        }}
        transition={{
          duration: 1,
          ease: "backInOut",
          delay: 0.2,
        }}
        cx="160.5"
        cy="265.5"
        rx="101.5"
        ry="43.5"
        fill="#1d1d1d"
      />
    </motion.svg>
  );
};

export default SquishyCard;

const BubbleText = () => {
  return (
    <h2 className="text-center text-3xl lg:text-5xl cursor-default font-thin text-white">
      {"Work Experience : Unfolding the chapters of Career".split("").map((child, idx) => (
        <span className={styles.hoverText} key={idx}>
          {child}
        </span>
      ))}
    </h2>
  );
};
