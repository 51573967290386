import React from 'react'
import ProjectCard from './ProjectCard';
import './../../src/TextLoading.css';
import { FiArrowLeft } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';


const ProductPage = () => {
  const navigate = useNavigate();
  const backToProjects = () =>{
    navigate('/projects')
  }
  return (
    <div className='fixed lg:absolute slide-down bg-bgProducts bg-contain lg:bg-cover bg-center text-white w-screen h-screen p-4'>
      <FiArrowLeft className="text-5xl text-neutral-50 cursor-pointer hover:text-slate-800" onClick={backToProjects}/>
      <div className='flex flex-col md:flex md:flex-row items-center justify-center py-10 slide-down-2'>
      <div className=''>
      <ProjectCard />
      <ProjectCard/>
      </div>
      </div>
    </div> 
  )
}

export default ProductPage
