import { FiBarChart, FiAward, FiArrowUpCircle, FiTrendingUp, FiBookOpen } from "react-icons/fi";
import { AnimatePresence, motion } from "framer-motion";
import { useWindowSize } from "./../hooks/useWindowSize";
import { useState } from "react";
import mainach from './../Images/achievementgif.gif';
import gdsc from './../Images/dfPodium.jpg'
import gfg from './../Images/gfgFirstEventTwo.jpeg'
import pgradar from './../Images/pgradar.jpg'
import hackathonwin from './../Images/HackathonWin.jpeg'

const VerticalAccordion = () => {
  const [open, setOpen] = useState(items[0].id);

  return (
      <div className="flex mt-32 lg:mt-14  flex-col lg:flex-row h-fit lg:h-[450px] w-full max-w-6xl mx-auto shadow overflow-hidden">
        {items.map((item) => {
          return (
            <Panel
              key={item.id}
              open={open}
              setOpen={setOpen}
              id={item.id}
              Icon={item.Icon}
              title={item.title}
              imgSrc={item.imgSrc}
              description={item.description}
            />
          );
        })}
      </div>
  );
};

const Panel = ({ open, setOpen, id, Icon, title, imgSrc, description }) => {
  const { width } = useWindowSize();
  const isOpen = open === id;

  return (
    <>
      <button
        className="bg-white hover:bg-slate-50 transition-colors p-3 border-r-[1px] border-b-[1px] border-slate-200 flex flex-row-reverse lg:flex-col justify-end items-center gap-4 relative group"
        onClick={() => setOpen(id)}
      >
        <span
          style={{
            writingMode: "vertical-lr",
          }}
          className="hidden lg:block text-xl font-light rotate-180"
        >
          {title}
        </span>
        <span className="block lg:hidden text-xl font-light">{title}</span>
        <div className="w-6 lg:w-full aspect-square bg-[#0064fe] text-white grid place-items-center">
          <Icon />
        </div>
        <span className="w-4 h-4 bg-white group-hover:bg-slate-50 transition-colors border-r-[1px] border-b-[1px] lg:border-b-0 lg:border-t-[1px] border-slate-200 rotate-45 absolute bottom-0 lg:bottom-[50%] right-[50%] lg:right-0 translate-y-[50%] translate-x-[50%] z-20" />
      </button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            key={`panel-${id}`}
            variants={width && width > 1024 ? panelVariants : panelVariantsSm}
            initial="closed"
            animate="open"
            exit="closed"
            style={{
              backgroundImage: `url(${imgSrc})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
            className="w-full h-full overflow-hidden relative bg-black flex items-end"
          >
            <motion.div
              variants={descriptionVariants}
              initial="closed"
              animate="open"
              exit="closed"
              className="px-4 py-2 bg-black/40 backdrop-blur-sm text-white"
            >
              <p>{description}</p>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default VerticalAccordion;

const panelVariants = {
  open: {
    width: "100%",
    height: "100%",
  },
  closed: {
    width: "0%",
    height: "100%",
  },
};

const panelVariantsSm = {
  open: {
    width: "100%",
    height: "200px",
  },
  closed: {
    width: "100%",
    height: "0px",
  },
};

const descriptionVariants = {
  open: {
    opacity: 1,
    y: "0%",
    transition: {
      delay: 0.125,
    },
  },
  closed: { opacity: 0, y: "100%" },
};

const items = [
  {
    id: 1,
    title: "My Significant Achievements",
    Icon: FiAward,
    imgSrc:
    mainach,
    description:
      "This section offers a concise glimpse into key achievements attained throughout my undergraduate journey, highlighting significant milestones and accomplishments.",
  },
  {
    id: 2,
    title: "GDSC Lead 2022-23",
    Icon: FiTrendingUp,
    imgSrc:
     gdsc,
    description:
      "As a GDSC (Google Developer Student Clubs) lead, I fostered a vibrant community of aspiring developers, organizing workshops, hackathons, and networking events. By collaborating with industry professionals, I empowered students to enhance their technical skills and pursue impactful projects.",
  },
  {
    id: 3,
    title: "GFG Supervisor 2022-23",
    Icon: FiBarChart,
    imgSrc:
      gfg,
    description:
      "As a Geeks for Geeks Student Chapter supervisor, I guided students in exploring diverse aspects of computer science through workshops, coding competitions, and seminars. Together, we cultivated an environment that fostered continuous learning and innovation, preparing students for success in the tech industry.",
  },
  {
    id: 4,
    title: "Runner-Up @ BitBox 3.0",
    Icon: FiArrowUpCircle,
    imgSrc:
      hackathonwin,
    description:
      "Proud to have been part of an amazing team that won the first runner up at the BITBOX 3.0 hackathon! It was an incredible experience, and I am grateful to have had the opportunity to work with such talented and dedicated individuals. The thrill of overcoming challenges and pushing boundaries made this experience unforgettable. Excited to see what we can achieve together next!",
  },
  {
    id: 5,
    title: "Published Research - PG Radar",
    Icon: FiBookOpen,
    imgSrc:
      pgradar,
    description:
      "Wrote a research paper on a new method of discorvering PG's (Paying Guests) and formulated a blockchain based contract system between the Owners and the Paying Guests.",
  },
];