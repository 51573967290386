import React from 'react'
import './../../src/TextLoading.css';
import ProjectCard from './ProjectCard';
import { useNavigate } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';
import {MdChevronLeft, MdChevronRight} from 'react-icons/md';
import MovieMatchMakerImage from './../Images/mmm-static-center.png'
import GitHubRadarImage from './../Images/githubRadarStatic.png'
import MiniProjectImage from './../Images/mini-projects.png'
import VideoTube from './../Images/videotube.png'
import { useRef } from 'react';
import objdetectorImage from './../Images/objdetector.png'
import WorkFlowBuilderImage from './../Images/WorkflowBuilder.png'

const WebProjectPage = () => {

  const navigate = useNavigate();
  const backToProjects = () =>{
    navigate('/projects')
  }
  return (
    <div className='fixed lg:absolute slide-down bg-bgWebProjects bg-contain lg:bg-cover bg-center p-4 text-white w-screen h-screen  '>
      <FiArrowLeft className='text-5xl text-neutral-50 cursor-pointer hover:text-slate-800' onClick={backToProjects}/>
      <div className='flex flex-row md:flex md:flex-col items-center justify-center py-10'>
      <div className='hidden md:block'>
      <div className='slide-down-2 flex items-center'>
      <ProjectCard
      projectTitle={"Workflow Builder"}
      projectImage={WorkFlowBuilderImage}
      projectLink={"https://nyg-workflow-builder.vercel.app/"}
      isUnResizedImage = {true}/>
      <ProjectCard 
      projectTitle={"Movie Match Maker"} 
      projectImage={MovieMatchMakerImage}
      projectLink={"https://granite-shaker-22b.notion.site/Movie-Match-Maker-8d0a66cd82324a90b858bca62af8f7a2"} />
      <ProjectCard
      projectTitle={"GitHub Radar"}
      projectImage={GitHubRadarImage}
      projectLink={"https://granite-shaker-22b.notion.site/GitHub-Radar-478aa887861d4a5d95137650436e8c1c?pvs=74"}/>
      </div>
      <div className='slide-up flex justify-center items-center'>
      <ProjectCard
      projectTitle={"Object Detector - Next App"}
      projectImage={objdetectorImage}
      projectLink={"https://nyg-objectdetector-v1.vercel.app/"}/>
      <ProjectCard
      projectImage={VideoTube}
      projectTitle={"VideoTube"}
      projectLink={"https://granite-shaker-22b.notion.site/VideoTube-A-Backend-Project-ea0e506d68f6468da2b90bf208647e5f?pvs=4"}/>
      <ProjectCard 
      projectTitle={"Mini Projects"}
      projectImage={MiniProjectImage}
      projectLink={"https://granite-shaker-22b.notion.site/Mini-Projects-Web-5fc4bb496df345fa9369d48c2dc31b81"}/>
      </div>
      </div>
      </div>
      <div className='mt-20 block md:hidden '>
      <div className='pl-48 pr-10 flex w-[400px] h-[400px] items-center justify-center overflow-x-scroll overflow-visible'>
      <ProjectCard 
        
      />
      <ProjectCard 
       projectTitle={"Movie Match Maker"} 
       projectImage={MovieMatchMakerImage}
       projectLink={"https://granite-shaker-22b.notion.site/Movie-Match-Maker-8d0a66cd82324a90b858bca62af8f7a2"}/>
      
      <ProjectCard 
      projectTitle={"GitHub Radar"}
      projectImage={GitHubRadarImage}
      projectLink={"https://granite-shaker-22b.notion.site/GitHub-Radar-478aa887861d4a5d95137650436e8c1c?pvs=74"}
      />
      <ProjectCard 
        projectImage={VideoTube}
        projectTitle={"VideoTube"}
        projectLink={"https://granite-shaker-22b.notion.site/VideoTube-A-Backend-Project-ea0e506d68f6468da2b90bf208647e5f?pvs=4"}
      />
      
      </div>
      </div>
    </div> 
  )
}

export default WebProjectPage
