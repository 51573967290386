import React from 'react'
import ShuffleHero from './ShuffleHero'
import Header from './Header'
import { useSelector } from "react-redux";
import {HoverImageLinks} from './BrowseMenu'

import './../../src/TextLoading.css';

const Hero = () => {

  const isMenu = useSelector((store)=> store.menu.isMenu)
  return (
    <>
   <div className='h-screen slide-down'>
      <Header/>
      {!isMenu&&<ShuffleHero/>}
      {isMenu&&<HoverImageLinks/>}
    </div>
    </>
  )
}

export default Hero


