import React from 'react';
import './../../src/ScrollDown.css';
import './../../src/TextLoading.css';

const OverLayCommunity = () => {
  return (
    <div className='slide-down'>
    <div className='bg-black bg-opacity-80 p-6 flex rounded-sm flex-col items-center justify-center  mb-6 font-sans'>
      <div className='text-xl lg:text-3xl text-[#0064fe] m-2 font-semibold'>Unleashing the Potential of Technical Communities and Networking</div>
      <div className='text-base lg:text-lg text-white m-2 '>
      In a rapidly evolving technical landscape, harnessing the collective intelligence 
      of communities and cultivating strong networks is pivotal. <br/>
      Feel free to hover or <span className='font-bold'>Scroll Down</span> to browse some amazing events I have contributed to.
      </div>
      <div className="box p-6">
          <span id='tg1'></span>
          <span id='tg2'></span>
          <span id='tg3'></span>
        </div>
    </div>
    </div>
  )
}

export default OverLayCommunity
