import React, { useRef, useState } from "react";
import { motion } from "framer-motion";


const ProjectCard = ({projectTitle,projectImage,projectLink,projectVideoLink}) => {
  return (
      <TiltCard projectTitle={projectTitle} projectImage={projectImage} projectLink={projectLink} projectVideoLink={projectVideoLink}/>
  );
};

const ROTATION_RANGE = 32.5;
const HALF_ROTATION_RANGE = 32.5 / 2;

const TiltCard = ({projectTitle,projectImage,projectLink,projectVideoLink,isUnResizedImage=false}) => {
  const ref = useRef(null);

  const [rotateX, setRotateX] = useState(0);
  const [rotateY, setRotateY] = useState(0);

  const handleMouseMove = (e) => {
    if (!ref.current) return;

    const rect = ref.current.getBoundingClientRect();

    const width = rect.width;
    const height = rect.height;

    const mouseX = (e.clientX - rect.left) * ROTATION_RANGE;
    const mouseY = (e.clientY - rect.top) * ROTATION_RANGE;

    const rY = mouseX / width - HALF_ROTATION_RANGE;
    const rX = (mouseY / height - HALF_ROTATION_RANGE) * -1;

    setRotateX(rX);
    setRotateY(rY);
  };

  const handleMouseLeave = () => {
    if (!ref.current) return;
    setRotateX(0);
    setRotateY(0);
  };

  return (
    <motion.div
      ref={ref}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      style={{
        transformStyle: "preserve-3d",
      }}
      animate={{
        rotateX,
        rotateY,
      }}
      className="relative min-h-60 min-w-60 lg:min-h-60 lg:min-w-80 m-4 rounded-xl bg-white bg-opacity-30"
    >
      <div
        style={{
          transform: "translateZ(100px)",
          transformStyle: "preserve-3d",
        }}
        className="absolute inset-4 grid place-content-center rounded-xl bg-[#1e1e1e] shadow-lg"
      >
        {/* <FiMousePointer
          style={{
            transform: "translateZ(75px)",
          }}
          className="mx-auto text-4xl"
        /> */}
        <p
          style={{
            transform: "translateZ(50px)",
          }}
          className="text-center text-2xl font-bold"
        >
          <a href={projectLink} target="__blank">
          <img src={projectImage} className="cursor-pointer grayscale-[70%] relative z-0 hover:grayscale-0 h-[240px] w-80 rounded-xl" alt="Project Img."/>
          </a>
          {projectVideoLink&&<button className="absolute z-20 -mt-[215px] ml-[100px]" >📽</button>}
          {/* <p className="text-white -mt-8 text-xl font-serif font-light">{projectTitle}</p> */}
        </p>
      </div>
    </motion.div>
  );
};

export default ProjectCard;