import { Provider } from "react-redux";
import Hero from "./components/Hero";
import CommunityWork from "./components/CommunityWork"
import AppStore from "./utils/appStore";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Projects from "./components/Projects";
import WebProjectPage from './components/WebProjectPage';
import AppProjectPage from './components/AppProjectPage';
import UxProjectPage from './components/UxProjectPage';
import ProductPage from './components/ProductPage'
import WorkExperience from "./components/WorkExperience";
import { useState, useEffect } from 'react';
import ANIMLOGO from './splashscreen.gif'
import PHONEANIMLOGO from './phoneLoadingAnim.gif'
import Content from "./components/Content";

function App() {

  const appRouter = createBrowserRouter([
    {
      path: "/",
      element: <Hero/>
    },
    {
      path: "/community-work",
      element: <CommunityWork/>
    },
    {
      path: "/projects",
      element: <Projects/>
    },
    {
      path:"/projects/web-projects",
      element:<WebProjectPage/>
    },
    {
      path:"/projects/app-projects",
      element: <AppProjectPage/>
    },
    {
      path:"/projects/ux-projects",
      element:<UxProjectPage/>
    },
    {
      path:"/projects/products",
      element:<ProductPage/>
    },
    {
      path:"/work-experience",
      element:<WorkExperience/>
    },
    {
      path:"/content",
      element:<Content/>
    }
  ])

  const [loading,setLoading] = useState(false);
  const [isLoadedOnce, setIsLoadedOnce] = useState(true);
    useEffect(()=>{
      if(isLoadedOnce){
        setLoading(true)
        setTimeout(()=>{
        setLoading(false)
        },4500)
      }
      
    },[])

  return (
    <>
    {loading&&(<div className="flex items-center justify-center bg-[#121212] w-screen h-screen ease-in-out duration-75">
    <img src={ANIMLOGO} className="hidden lg:block w-screen h-screen" alt="Logo Loading Animation"/>
    <img src={PHONEANIMLOGO} className="block lg:hidden w-[400px] h-[400px]" alt="Logo Loading Animation"/>
    </div>)}
   {!loading&&<Provider store={AppStore}>
    <div className="bg-[#121212]">
      <RouterProvider router={appRouter} />
    </div>
    </Provider>}
    </> 
  );
}

export default App;
