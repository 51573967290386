import React , {useRef,useEffect} from 'react'
import { CommDashboard } from './CommDashboard';
import EventsCarousel from './EventsCarousel';
import { Link, useLocation } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';

const CommunityWork = () => {
  const eventsRef = useRef();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/events'&& eventsRef.current) {
      eventsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);

  return (
    <div className="w-[100%] h-[98%] mb-2 mx-0">
      <div>
        <Link to="/" className='text-5xl hover:text-white m-4 absolute z-[1000]'>
          <FiArrowLeft className='text-white hover:text-slate-800'/>
        </Link>
      </div>
      <CommDashboard/>
      <div ref={eventsRef}>
      <EventsCarousel/>
      </div>
    </div>
  )
}

export default CommunityWork;
