import React, { useState } from 'react'
import SquishyCard from './SquishyCard'
import { Link } from 'react-router-dom';
import { FiArrowLeft, FiAward } from 'react-icons/fi';
import './../../src/TextLoading.css';
import { Tooltip as ReactTooltip } from "react-tooltip";
import VerticalAccordion from './AchievementsCards';

const WorkExperience = () => {
  const [isWorkExperience, setIsWorkExperience] = useState(true);  
  const toggleTabs = () =>{
    setIsWorkExperience(!isWorkExperience);
  }  

  return (
    <div className='fixed lg:relative bg-bgwe bg-cover h-screen'>
    <div className='flex justify-between items-center'>
    <Link to="/" className='text-5xl hover:text-white m-4 '>
        <FiArrowLeft className='text-white hover:text-slate-400'/>
    </Link>
    <span>
        <span onClick={toggleTabs}><FiAward data-tooltip-id="my-tooltip-2" className='text-[#0064fe] hover:text-[#3283ff] text-4xl hover:scale-105 ease-in-out duration-150 m-4 mt-5 cursor-pointer' /></span>
    </span>
    </div>
    {isWorkExperience&&<div className='slide-down'>
      <SquishyCard/>
    </div>}
    {!isWorkExperience&&<div className='slide-down'>
        <VerticalAccordion/>
    </div>}

    <ReactTooltip
        id="my-tooltip-2"
        place="bottom"
        variant="info"
        content="Click to change between Experience and Achievements."
      />
    </div>
  )
}

export default WorkExperience
