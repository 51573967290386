import { motion, useTransform, useScroll } from "framer-motion";
import { useRef } from "react";
import df23Cover from './../Images/df2023.jpeg';
import android23Cover from './../Images/gdscAmityEventCover.jpeg';
import hackHavenCover from './../Images/hackHavenCoverPhoto.jpeg';
import cictnCover from './../Images/CICTN.jpeg';
import uosCover from './../Images/gfgFirstEventTwo.jpeg';
import solutionChallengeCover from './../Images/solutionChallengeCover.jpeg';
import df22Cover from './../Images/devfest1.jpeg';
import tedxCover from './../Images/tedxCoverjpeg.jpeg';
import composeCover from './../Images/cc22Cover.png';
import ttinCover from './../Images/ttinCover.jpeg';
import asjCover from './../Images/asj22.png'

const EventsCarousel = () => {
  return (
    <div className="bg-white" id="eventsCarousel">
      <HorizontalScrollCarousel />
    </div>
  );
};

const HorizontalScrollCarousel = () => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
  });

  const x = useTransform(scrollYProgress, [0, 1], ["1%", "-80%"]);

  return (
    <section ref={targetRef} className="relative h-[300vh] bg-white">
      <div className="sticky top-0 flex h-screen items-center overflow-hidden">
        <span className="text-6xl px-6 py-4 text-[#0064fe] font-semibold">Some Amazing Events <br/>I have Contributed to :</span>
        <motion.div style={{ x }} className="flex gap-4">
          {cards.map((card) => {
            return <Card card={card} key={card.id} />;
          })}
        </motion.div>
      </div>
    </section>
  );
};

const Card = ({ card }) => {
  return (
    <div
      key={card.id}
      className="group relative h-[450px] w-[450px] overflow-hidden bg-neutral-200 shadow-lg shadow-black"
    >
      <a href={card.link} target="_blank">
      <div
        style={{
          backgroundImage: `url(${card.url})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="absolute inset-0 z-0 transition-transform duration-300 group-hover:scale-110"
      ></div>
      <div className="absolute inset-0 z-10 grid place-content-center">
        <p className="bg-gradient-to-br from-black/80 to-white/0 p-8 text-6xl font-black uppercase text-white ">
          {card.title}
        </p>
      </div>
      </a>
    </div>
  );
};

export default EventsCarousel;

const cards = [
  {
    url: hackHavenCover,
    title: "Hack Haven",
    id: 2,
    link: "https://medium.com/@coolyofandom/the-story-of-hackhaven-aa55400954fe"
  },
  {
    url: df23Cover,
    title: "DevFest Noida'23",
    id: 1,
    link: "https://www.commudle.com/communities/gdg-noida/events/devfest-noida-2023"
  },
  {
    url: android23Cover,
    title: "Android Workshop'23",
    id: 3,
    link: "https://www.linkedin.com/posts/abhishektyagi162_thrilled-to-have-co-hosted-an-getting-started-ugcPost-7145057907976380416-UT4d?utm_source=share&utm_medium=member_desktop"
  },
  {
    url: cictnCover,
    title: "CICTN @ABESEC",
    id: 4,
    link: "https://www.linkedin.com/posts/notyashgrover_grateful-to-have-had-the-opportunity-to-host-activity-7055379375390605312-REfM?utm_source=share&utm_medium=member_desktop"
  },
  {
    url: solutionChallengeCover,
    title: "Solution Challenge'23",
    id: 5,
    link: "https://www.linkedin.com/posts/notyashgrover_gdsc-abesec-in-collaboration-with-9-other-activity-7024982552473931776-9I5d?utm_source=share&utm_medium=member_desktop"
  },
  {
    url: df22Cover,
    title: "DevFest Noida'22",
    id: 6,
    link: "https://www.linkedin.com/posts/notyashgrover_im-at-a-loss-for-words-to-express-my-feelings-activity-7008004013749104640-HmT2?utm_source=share&utm_medium=member_desktop"
  },
  {
    url: tedxCover,
    title: "TedX ABESEC'22",
    id: 7,
    link: "https://www.linkedin.com/posts/notyashgrover_hello-ji-kaise-ho-saare-i-finally-met-activity-6997744411241537536-kYrM?utm_source=share&utm_medium=member_desktop"
  },
  {
    url: composeCover,
    title: "Compose Camps'22",
    id: 8,
    link: "https://www.linkedin.com/posts/notyashgrover_compose-camps-intro-session-activity-6982393728791392257-Fjzc?utm_source=share&utm_medium=member_desktop"
  },
  {
    url: ttinCover,
    title: "Tech-A-Thon 3.0 by iNeuron.ai",
    id: 9,
    link: "https://www.linkedin.com/posts/notyashgrover_i-personally-believe-that-hackathons-harness-activity-6981672174596042752-4dCb?utm_source=share&utm_medium=member_desktop"
  },
  {
    url: asjCover,
    title: "Android Study Jams'21",
    id: 10,
    link: "https://www.linkedin.com/posts/notyashgrover_gdsc-androiddevelopment-googledevelopers-activity-6912333892440002561-9D9t?utm_source=share&utm_medium=member_desktop"
  },
  {
    url: uosCover,
    title: "Unboxing Open Source",
    id: 11,
    link: "https://www.linkedin.com/posts/notyashgrover_throwback-opensource-activity-6858400822863138817-9SaW?utm_source=share&utm_medium=member_desktop"
  },
];