import React from 'react'
import ProjectCard from './ProjectCard';
import './../../src/TextLoading.css';
import { useNavigate } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';
import ManageItImage from './../Images/manageItImage.png'
import AcaryaLogo from './../Images/acaryaLogo.png'
import MiniProjects from './../Images/mini-projects.png'
import ecoEcho from './../Images/EcoEcho.png'
import { AnimatePresence} from "framer-motion";
import { FiWatch } from "react-icons/fi";
import { motion } from "framer-motion";
import { useState } from 'react';

const AppProjectPage = () => {
  const navigate = useNavigate();
  const backToProjects = () =>{
    navigate('/projects')
  }
  const [isOpen, setIsOpen] = useState(false);
  const handleModalClick = () =>{
    setIsOpen(true)
  }
  return (
    <div className='fixed lg:absolute slide-down bg-bgAppProjects bg-contain  lg:bg-cover bg-center text-white w-screen h-screen p-4'>
      <FiArrowLeft className='text-5xl text-neutral-50 cursor-pointer hover:text-slate-800' onClick={backToProjects}/>
      <div className='flex flex-col items-center justify-center py-10'>
      <div className='hidden md:block'>
      <div className='slide-down-2 flex items-center'>
      <ProjectCard
      projectImage={ManageItImage}
      projectTitle={"Manage It"}
      projectLink={"https://www.canva.com/design/DAE4rIJxNss/doDcuWOxtMjbMw4iGwJROg/view"}/>
      <ProjectCard
      projectImage={AcaryaLogo}
      projectTitle={"Acarya : A fitness app"}
      projectLink={"https://granite-shaker-22b.notion.site/Acarya-A-Fitness-App-63df68074a674a29986422bc6a862937?pvs=4"}/>
      </div>
      <div className='slide-up flex items-center'>
      <ProjectCard
      projectImage={MiniProjects}
      projectTitle={"Mini Projects : Android"}
      projectLink={"https://github.com/theyashgrover/Android-Dev.-So-Far"}
      />
      <span onClick={handleModalClick}>
      <ProjectCard
      projectImage={ecoEcho}
      projectTitle={"EcoEcho"}
     />
     </span>
      </div>
      </div>
      </div>
      <div className='mt-20 block md:hidden '>
      <div className='pl-48 pr-10 flex w-[400px] h-[400px] items-center justify-center overflow-x-scroll overflow-visible'>
      <ProjectCard 
        
      />
      <ProjectCard 
        projectImage={ManageItImage}
        projectTitle={"Manage It"}
        projectLink={"https://www.canva.com/design/DAE4rIJxNss/doDcuWOxtMjbMw4iGwJROg/view"}/>
      
      <ProjectCard 
        projectImage={AcaryaLogo}
        projectTitle={"Acarya : A fitness app"}
        projectLink={"https://granite-shaker-22b.notion.site/Acarya-A-Fitness-App-63df68074a674a29986422bc6a862937?pvs=4"}
      />
      <ProjectCard 
        projectImage={MiniProjects}
        projectTitle={"Mini Projects : Android"}
        projectLink={"https://github.com/theyashgrover/Android-Dev.-So-Far"}
      />
      
      </div>
      </div>
      <SpringModal  isOpen={isOpen} setIsOpen={setIsOpen}/>
    </div> 
  )
}

export default AppProjectPage

const SpringModal = ({ isOpen, setIsOpen }) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={() => setIsOpen(false)}
          className="bg-slate-900/20 backdrop-blur p-8 fixed inset-0 z-50 grid place-items-center overflow-y-scroll cursor-pointer"
        >
          <motion.div
            initial={{ scale: 0, rotate: "12.5deg" }}
            animate={{ scale: 1, rotate: "0deg" }}
            exit={{ scale: 0, rotate: "0deg" }}
            onClick={(e) => e.stopPropagation()}
            className="bg-gradient-to-br from-green-400 to-emerald-400 text-white p-6 rounded-lg w-full max-w-lg shadow-xl cursor-default relative overflow-hidden"
          >
            <FiWatch className="text-white/10 rotate-12 text-[250px] absolute z-0 -top-24 -left-24" />
            <div className="relative z-10">
              <div className="bg-white w-16 h-16 mb-2 rounded-full text-3xl text-emerald-400 grid place-items-center mx-auto">
                <FiWatch />
              </div>
              <h3 className="text-3xl font-bold text-center mb-2">
                Hey there!
              </h3>
              <p className="text-center mb-6">
             This app is under development phase as of now. You will get a glimpse of the same soon here.
        
              </p>
              <div className="flex gap-2">
                <button
                  onClick={() => setIsOpen(false)}
                  className="bg-transparent hover:bg-white/10 transition-colors text-white font-semibold w-full py-2 rounded"
                >
                  Okay!
                </button>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
