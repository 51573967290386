import React from 'react';
import {BouncyCardsFeatures} from './CategoryProjects';
import { Link } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';

const Projects = () => {
  return (
    <div className='bg-bgProjectsPage bg-cover bg-center'>
    <Link to="/" className='text-5xl hover:text-white m-4 absolute z-[1000]'>
      <FiArrowLeft className='text-white hover:text-slate-800'/>
    </Link>
    <div className='flex items-center justify-center'>
      <div className='p-2 mt-8 '>
      <BouncyCardsFeatures />
      </div>
    </div>
    </div>
  )
}

export default Projects;
