import React from "react";
import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom'
import styles from "./../bubble.module.css";

export const BouncyCardsFeatures = () => {
  const navigate = useNavigate();
  const handleClickWeb = () =>{
      navigate('/projects/web-projects');
  }
  const handleClickApp = () =>{
    navigate('/projects/app-projects');
  }
  const handleClickUX = () =>{
  navigate('/projects/ux-projects');
  }
  const handleClickProducts = () =>{
  // navigate('/products');
  console.log("Products will be launched soon.")
  }
  return (
    <section className="mx-auto min-w-7xl px-4 py-8">
        <h2 className="flex items-center justify-center text-white font-semibold text-3xl md:text-4xl lg:text-5xl ml-2 mb-6 py-2">
          <BubbleText/>
          </h2>
      <div className="mb-4 grid grid-cols-12 gap-2">
        <BounceCard className="col-span-12 md:col-span-4">
          <CardTitle>Web Projects</CardTitle>
          <div className="absolute bottom-0 left-4 right-4 top-32 translate-y-8 rounded-t-2xl bg-gradient-to-br from-violet-400 to-indigo-400 p-4 transition-transform duration-[250ms] group-hover:translate-y-4 group-hover:rotate-[2deg]" onClick={handleClickWeb}>
            <span className="block text-center font-semibold text-indigo-50">
            Take a Closer Look
            </span>
          </div>
        </BounceCard>
        <BounceCard className="col-span-12 md:col-span-8">
          <CardTitle>Mobile Applications</CardTitle>
          <div className="absolute bottom-0 left-4 right-4 top-32 translate-y-8 rounded-t-2xl bg-gradient-to-br from-green-400 to-emerald-400 p-4 transition-transform duration-[250ms] group-hover:translate-y-4 group-hover:rotate-[2deg]"onClick={handleClickApp}>
            <span className="block text-center font-semibold text-orange-50">
              Click to Browse
            </span>
          </div>
        </BounceCard>
      </div>
      <div className="grid grid-cols-12 gap-2">
        <BounceCard className="col-span-12 md:col-span-8">
          <CardTitle>UX Projects</CardTitle>
          <div className="absolute bottom-0 left-4 right-4 top-32 translate-y-8 rounded-t-2xl bg-gradient-to-br from-amber-400 to-orange-400 p-4 transition-transform duration-[250ms] group-hover:translate-y-4 group-hover:rotate-[2deg]" onClick={handleClickUX}>
            <span className="block text-center font-semibold text-emerald-50">
              Explore Case Studies
            </span>
          </div>
        </BounceCard>
        <BounceCard className="col-span-12 md:col-span-4">
          <CardTitle>Products</CardTitle>
          <div className="absolute bottom-0 left-4 right-4 top-32 translate-y-8 rounded-t-2xl bg-gradient-to-br from-pink-400 to-red-400 p-4 transition-transform duration-[250ms] group-hover:translate-y-4 group-hover:rotate-[2deg]" onClick={handleClickProducts}>
            <span className="block text-center font-semibold text-red-50">
              Launching Soon
            </span>
          </div>
        </BounceCard>
      </div>
    </section>
  );
};

const BounceCard = ({ className, children }) => {
    return (
      <motion.div
        whileHover={{ scale: 0.95, rotate: "-1deg" }}
        className={`group relative min-h-[360px] cursor-pointer overflow-hidden rounded-2xl bg-slate-800 bg-opacity-40 p-8 ${className}`}
      >
        {children}
      </motion.div>
    );
  };

const CardTitle = ({ children }) => {
  return (
    <h3 className="mx-auto text-center text-white text-3xl">{children}</h3>
  );
};

const BubbleText = () => {
  return (
    <h2 className="text-center text-5xl font-thin cursor-default text-white">
      {"Projects : Unfolding the Narrative of my Work ".split("").map((child, idx) => (
        <span className={styles.hoverText} key={idx}>
          {child}
        </span>
      ))}
    </h2>
  );
};
