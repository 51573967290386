import { useMotionValue, motion, useSpring, useTransform } from "framer-motion";
import React, { useRef } from "react";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import aboutMe from "./../Images/AboutMe.png";
import workExp from "./../Images/Experience.png";
import community from "./../Images/Community.png";
import content from "./../Images/Content.png";
import project from "./../Images/Project.png";
import { useDispatch } from "react-redux";
import { addBoolean } from "../utils/menuSlice";
import { useSelector } from "react-redux";

export const HoverImageLinks = () => {
  const navigate = useNavigate();
  
  // const backToHome = () =>{
  //   navigate("/");
  // }
 
  return (
    <section className="bg-[#121212] p-2 md:p-4 scrollbar-hide">
      {/* <FiArrowLeft onClick={backToHome} className="text-2xl md:text-5xl text-white cursor-pointer hover:text-gray-400"/> */}
      <div className="mx-auto max-w-5xl">
        <Link
          heading="Work Experience"
          subheading="Browse my work history"
          imgSrc={workExp}
          to="/work-experience"
        />
        <Link
          heading="Projects"
          subheading="Work speaks for itself"
          imgSrc={project}
          to="/projects"
        />
        <Link
          heading="Community Work"
          subheading="Checkout Events & Communities having cool geeks"
          imgSrc={community}
          to="/community-work"
        />
        <Link
          heading="Content"
          subheading="Incase you're eager to learn with me"
          imgSrc={content}
          to="/content"
        />
      </div>
      
    </section>
  );
};

const Link = ({ heading, imgSrc, subheading, to,onClickk }) => {
  const ref = useRef(null);
  const navigate = useNavigate();

  const x = useMotionValue(0);
  const y = useMotionValue(0);

  const mouseXSpring = useSpring(x);
  const mouseYSpring = useSpring(y);

  const top = useTransform(mouseYSpring, [0.5, -0.5], ["40%", "60%"]);
  const left = useTransform(mouseXSpring, [0.5, -0.5], ["60%", "70%"]);

  const handleMouseMove = (e) => {
    const rect = ref.current.getBoundingClientRect();

    const width = rect.width;
    const height = rect.height;

    const mouseX = e.clientX - rect.left;
    const mouseY = e.clientY - rect.top;

    const xPct = mouseX / width - 0.5;
    const yPct = mouseY / height - 0.5;

    x.set(xPct);
    y.set(yPct);
  };
  const isMenu = useSelector((store)=> store.menu.isMenu)
  const dispatch = useDispatch();
  const takeme = () =>{
    navigate(to);
    const setMenuStateBooleanValue = () =>{
    dispatch(addBoolean(!isMenu))
  }
  setMenuStateBooleanValue()
  }

  return (
    <motion.a
      ref={ref}
      to={to}
      onMouseMove={handleMouseMove}
      initial="initial"
      whileHover="whileHover"
      className="group mt-4 lg:-mt-4 -mb-2 relative flex items-center justify-between border-b-2 border-white py-4 transition-colors duration-500 hover:border-gray-200 md:py-8"
      animate={{ scale: 0.95 }}
      transition={{
        ease: "linear",
        duration: 0.5,
        x: { duration: 1 }
      }}
    >
      <div>
        <motion.span
          onClick={takeme}
          variants={{
            initial: { x: 0 },
            whileHover: { x: -16 },
          }}
          transition={{
            type: "spring",
            staggerChildren: 0.075,
            delayChildren: 0.25,
          }}
          className="relative z-10 block text-2xl font-bold cursor-pointer text-white transition-colors duration-500 group-hover:text-gray-200 md:text-6xl"
        >
          {heading.split("").map((l, i) => (
            <motion.span
              variants={{
                initial: { x: 0 },
                whileHover: { x: 16 },
              }}
              transition={{ type: "spring" }}
              className="inline-block"
              key={i}
            >
              {l}
            </motion.span>
          ))}
        </motion.span>
        <span className="relative z-10 mt-2 block text-sm md:text-base text-neutral-500 transition-colors duration-500 group-hover:text-gray-200 cursor-pointer">
          {subheading}
        </span>
      </div>

      <motion.img
        style={{
          top,
          left,
          translateX: "-50%",
          translateY: "-50%",
        }}
        variants={{
          initial: { scale: 0, rotate: "-12.5deg" },
          whileHover: { scale: 1, rotate: "12.5deg" },
        }}
        transition={{ type: "spring" }}
        src={imgSrc}
        className="absolute z-0 h-24 w-32 rounded-lg object-cover md:h-48 md:w-64"
        alt={`Image representing a link for ${heading}`}
      />

      <motion.div
        variants={{
          initial: {
            x: "25%",
            opacity: 0,
          },
          whileHover: {
            x: "0%",
            opacity: 1,
          },
        }}
        transition={{ type: "spring" }}
        className="relative z-10 p-4"
      >
        <FiArrowRight onClick={takeme} className="text-5xl text-neutral-50 cursor-pointer" />
      </motion.div>
    </motion.a>
  );
};