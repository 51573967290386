import React from 'react';
import Contentgif from './../Images/contentPage.gif';
import { FiHome } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import './../../src/TextLoading.css';
// import Portfolio from './Newspaper';

const Content = () => {
    const navigate = useNavigate()
    const handleHomeClick = () =>{
        navigate("/");
    }
    return (
    <div onClick={handleHomeClick} className='h-screen w-screen slide-down bg-[#3c3c3c] flex flex-col justify-center items-center'>
        <FiHome className='text-2xl text-white cursor-pointer hover:text-gray-400 mb-4'/>
        <img src={Contentgif} className='w-[400px] h[400px]' alt='Content Page' />
    </div>
    // <Portfolio/>
  )
}

export default Content
